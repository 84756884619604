var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"stretch","atid":"default_config_view"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.isLoadingDefaultConfig || _vm.isLoadingConfigsDiff}},[_c('v-card-title',[_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.$lang("defaultConfig.title"))+" ")]),_c('test-info')],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-tabs',{attrs:{"background-color":"transparent","grow":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{attrs:{"href":"#tab-stage"}},[(
                  _vm.ConfigUtil.hasDiffBetweenConfigs(
                    _vm.initialConfig.response.stage.response,
                    _vm.defaultConfig.response.stage.response
                  )
                )?_c('div',{staticClass:"warning rounded-circle",staticStyle:{"width":"10px","height":"10px"}}):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$lang("commonConfig.stage"))+" ")])]),_c('v-tab',{attrs:{"href":"#tab-production"}},[(
                  _vm.ConfigUtil.hasDiffBetweenConfigs(
                    _vm.initialConfig.response.production.response,
                    _vm.defaultConfig.response.production.response
                  )
                )?_c('div',{staticClass:"warning rounded-circle",staticStyle:{"width":"10px","height":"10px"}}):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$lang("commonConfig.production"))+" ")])]),_c('v-tab-item',{attrs:{"value":"tab-stage"}},[_c('config-response-editor',{attrs:{"value":_vm.localConfigResponse.stage.response,"active-tab":_vm.activeTab,"default-config-response":_vm.defaultConfig.response,"initial-config":_vm.initialConfig.response,"has-non-deployed-changes":_vm.ConfigUtil.hasDiffBetweenConfigs(
                    _vm.initialConfig.response.stage.response,
                    _vm.defaultConfig.response.stage.response
                  ),"is-deployer-visible":true,"is-deployment-in-progress":_vm.isDeploymentInProgress,"preview-items-after-transfer":_vm.previewItemsAfterTransfer.stage,"has-deploy-access":_vm.defaultConfig.hasDeployAccess,"atid":"config_view"},on:{"save":_vm.setConfig,"deploy":_vm.deployToStage,"transfer":_vm.transferToProd,"import":_vm.handleImportToStage,"copyResponse":_vm.handleCopyResponse}})],1),_c('v-tab-item',{attrs:{"value":"tab-production"}},[_c('config-response-editor',{attrs:{"value":_vm.localConfigResponse.production.response,"active-tab":_vm.activeTab,"default-config-response":_vm.defaultConfig.response,"initial-config":_vm.initialConfig.response,"has-non-deployed-changes":_vm.ConfigUtil.hasDiffBetweenConfigs(
                    _vm.initialConfig.response.production.response,
                    _vm.defaultConfig.response.production.response
                  ),"is-deployer-visible":true,"is-deployment-in-progress":_vm.isDeploymentInProgress,"preview-items-after-transfer":_vm.previewItemsAfterTransfer.production,"has-deploy-access":_vm.defaultConfig.hasDeployAccess,"atid":"config_view"},on:{"save":_vm.setConfig,"deploy":_vm.deployToProd,"transfer":_vm.transferToStage,"copyResponse":_vm.handleCopyResponse}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }