

























































































































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { NavigationGuardNext, Route } from "vue-router";
import { cloneDeep } from "lodash";

import TestInfo from "@/ab-tests/components/TestInfo.vue";
import ConfigResponseEditor from "@/ab-tests/components/ConfigResponseEditor.vue";
import DefaultConfigurationModel from "@/ab-tests/models/DefaultConfigurationModel";
import ApplicationResponseModel, {
  EnvironmentModel,
  EnvironmentType,
} from "@/ab-tests/models/ApplicationResponseModel";
import ItemPreviewModel, {
  PREVIEW_ITEM_TYPE,
} from "@/ab-tests/models/ItemPreviewModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import JsonUtil from "@/ab-tests/utils/JsonUtil";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";

@Component({
  components: {
    ConfigResponseEditor,
    TestInfo,
  },
})
export default class DefaultConfigurationView extends mixins(
  UnsavedChangesMixin
) {
  readonly ConfigUtil = ConfigUtil;
  defaultConfig = new DefaultConfigurationModel();
  localConfigResponse = new ApplicationResponseModel();
  activeTab: null | string = null;
  isWatchedDefault = false;
  previewItemsAfterTransfer: Record<string, Array<ItemPreviewModel>> = {
    stage: [],
    production: [],
  };

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.defaultConfig);
  }

  get appId(): string {
    return this.$route.params.id;
  }

  get isLoadingConfigsDiff(): boolean {
    return this.$store.state.configsStore.isLoadingConfigsDiff;
  }

  get isLoadingDefaultConfig(): boolean {
    return this.$store.state.defaultConfig.loadingDefaultConfig;
  }

  get isDeploymentInProgress(): boolean {
    return this.$store.state.defaultConfig.deploymentInProgress;
  }

  get initialConfig(): DefaultConfigurationModel {
    return this.$store.state.defaultConfig.config;
  }

  @Watch("appId", { immediate: true })
  async watchAppId() {
    await this.$store.dispatch("loadDefaultConfig");
  }

  @Watch("initialConfig", { deep: true })
  private updateConfig(value: DefaultConfigurationModel) {
    this.defaultConfig = DefaultConfigurationModel.of(value);
    this.localConfigResponse = ApplicationResponseModel.of(value.response);

    if (!this.isWatchedDefault) {
      this.isWatchedDefault = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  @Watch("localConfigResponse", { deep: true })
  private watchLocalConfigResponse(
    localConfigResponse: ApplicationResponseModel
  ) {
    this.defaultConfig.response =
      ApplicationResponseModel.of(localConfigResponse);
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  setConfig(value: string) {
    if (this.activeTab === "tab-stage") {
      this.localConfigResponse.stage.response = value;
    }

    if (this.activeTab === "tab-production") {
      this.localConfigResponse.production.response = value;
    }

    this.defaultConfig.response = ApplicationResponseModel.of(
      this.localConfigResponse
    );

    this.isSavedForm = false;
  }

  async deployToProd(isDeployAndSync: boolean) {
    if (isDeployAndSync) {
      this.localConfigResponse.stage.response =
        this.localConfigResponse.production.response;
    }

    this.defaultConfig.response = ApplicationResponseModel.of(
      this.localConfigResponse
    );
    const defaultConfigForRequest = cloneDeep(this.defaultConfig);

    if (!this.defaultConfig?.id) {
      await this.$store.dispatch(
        "createDefaultConfig",
        defaultConfigForRequest
      );
    } else {
      await this.$store.dispatch("deployDefaultConfig", {
        response: defaultConfigForRequest.response,
        environmentType: EnvironmentType.PROD,
      });

      if (isDeployAndSync) {
        await this.$store.dispatch("deployDefaultConfig", {
          response: defaultConfigForRequest.response,
          environmentType: EnvironmentType.STAGE,
        });
      }
    }

    this.resetPreviewItemsData();
    this.isSavedForm = true;
  }

  async deployToStage(isDeployAndSync: boolean) {
    if (isDeployAndSync) {
      this.localConfigResponse.production.response =
        this.localConfigResponse.stage.response;
    }

    this.defaultConfig.response = ApplicationResponseModel.of(
      this.localConfigResponse
    );
    const defaultConfigForRequest = cloneDeep(this.defaultConfig);

    if (!this.defaultConfig?.id) {
      await this.$store.dispatch("createDefaultConfig", this.defaultConfig);
    } else {
      await this.$store.dispatch("deployDefaultConfig", {
        response: defaultConfigForRequest.response,
        environmentType: EnvironmentType.STAGE,
      });

      if (isDeployAndSync) {
        await this.$store.dispatch("deployDefaultConfig", {
          response: defaultConfigForRequest.response,
          environmentType: EnvironmentType.PROD,
        });
      }
    }

    this.resetPreviewItemsData();
    this.isSavedForm = true;
  }

  transferToProd() {
    this.localConfigResponse.production.response =
      this.localConfigResponse.stage.response;
    this.defaultConfig.response = ApplicationResponseModel.of(
      this.localConfigResponse
    );
    this.fillPreviewItemsAfterTransfer("production", "stage");
    this.activeTab = "tab-production";
    this.isSavedForm = false;
  }

  transferToStage() {
    this.localConfigResponse.stage.response =
      this.localConfigResponse.production.response;
    this.defaultConfig.response = ApplicationResponseModel.of(
      this.localConfigResponse
    );
    this.fillPreviewItemsAfterTransfer("stage", "production");
    this.activeTab = "tab-stage";
    this.isSavedForm = false;
  }

  fillPreviewItemsAfterTransfer(to: string, from: string) {
    this.previewItemsAfterTransfer[to] = [
      ...JsonUtil.formattingFromJson(
        this.initialConfig.response[to as "stage" | "production"].response
      )
        .filter(({ key }: { key: string }) =>
          JsonUtil.formattingFromJson(
            this.localConfigResponse[from as "stage" | "production"].response
          ).every((item: any) => item.key !== key)
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.DELETED,
        })),
      ...JsonUtil.formattingFromJson(
        this.localConfigResponse[from as "stage" | "production"].response
      )
        .filter(({ key, value }: { key: string; value: string }) =>
          JsonUtil.formattingFromJson(
            this.initialConfig.response[to as "stage" | "production"].response
          ).some((item: any) => item.key === key && item.value !== value)
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.EDITED,
        })),
      ...JsonUtil.formattingFromJson(
        this.localConfigResponse[from as "stage" | "production"].response
      )
        .filter(({ key }: { key: string }) =>
          JsonUtil.formattingFromJson(
            this.initialConfig.response[to as "stage" | "production"].response
          ).every((item: any) => item.key !== key)
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.ADDED,
        })),
    ];
  }

  resetPreviewItemsData() {
    this.previewItemsAfterTransfer = {
      stage: [],
      production: [],
    };
  }

  handleImportToStage(value: string) {
    this.localConfigResponse.stage.response = value;
  }

  handleCopyResponse({
    payload,
    field,
  }: {
    payload: ApplicationResponseModel;
    field: "stage" | "production";
  }) {
    if (this.activeTab === "tab-stage") {
      this.localConfigResponse.stage.response = (
        payload[field] as EnvironmentModel
      ).response;
    } else if (this.activeTab === "tab-production") {
      this.localConfigResponse.production.response = (
        payload[field] as EnvironmentModel
      ).response;
    }
  }
}
